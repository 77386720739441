export const DISCOUNT = 0.05;
export const PREMIUM = 0.2;

export const PAYPAL_LIMIT = 10000;

export const COMFORT_COLORS = {
  ECONOMY: '#eeff41',
  STANDARD: '#ff4081',
  PREMIUM: '#e040fb'
};

const whatsIncludedText = `The standard package with the basic price includes
  accommodation, certain amenities, and selected experiences, basically all the
  necessities you have always wanted on vacation, so you will almost not have to
  reach for your wallet. The beauty is that in one of the next steps, you would
  have the option to extend this package.`;

const organizeWeekText = `First take a look at the basic experiences that are already there.
  Then go ahead and choose your favorite experiences to include in your own personalized
  week taking full control your week’s layout, time and cost.`;

export const MODAL_DATA = {
  included: { title: 'What is included?', message: whatsIncludedText },
  organizeWeek: { title: 'Organize ultimate week', message: organizeWeekText }
};

export const IMG_URLS = {
  week: 'https://d274wajlpib2qu.cloudfront.net/wp-content/uploads/2018/10/04103533/villaweek_2017_017_sea_motoryacht_brac_hvar_solta_vis-marko-web-1280x853.jpg',
  weekend: 'https://d274wajlpib2qu.cloudfront.net/wp-content/uploads/2018/10/04104635/villaweek_2018_081_adventure_atv_hrvace_sinj_cetina-web-1280x853.jpg',
  shared: 'https://villaweek.s3-eu-west-1.amazonaws.com/wp-content/uploads/2018/10/04103706/villaweek_2017_160_party_after_beach_hula_hvar-marko-web-1280x853.jpg',
  private: 'https://d274wajlpib2qu.cloudfront.net/wp-content/uploads/2018/10/04104153/villaweek_2018_025_sea_motoryacht_brac_hvar_solta_vis-web-1280x853.jpg'
};
