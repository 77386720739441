import { extractData, processParams } from '@/common/api/helpers';
import join from 'url-join';
import request from '@/common/api/request';

const urls = {
  root: '/users',
  resource: id => join(urls.root, `${id}`),
  invite: id => join(urls.resource(id), 'invite')
};

function fetch(params = {}) {
  return request.get(urls.root, { params: processParams(params) })
    .then(extractData);
}

function create(item) {
  return request.post(urls.root, item).then(extractData);
}

function update(item) {
  return request.patch(urls.resource(item.id), item).then(extractData);
}

function remove(item) {
  return request.delete(urls.resource(item.id));
}

function invite(item) {
  return request.post(urls.invite(item.id));
}

export default {
  fetch,
  create,
  update,
  remove,
  invite
};
