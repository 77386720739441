'use strict';

const Gender = require('./gender');
const PaymentProvider = require('./paymentProvider');
const Role = require('./role');
const RoomType = require('./roomType');
const WeekStatus = require('./weekStatus');

module.exports = {
  Gender,
  PaymentProvider,
  Role,
  RoomType,
  WeekStatus
};
