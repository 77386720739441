import { extractData } from './helpers';
import path from 'path';
import request from './request';

const urls = {
  root: '/users',
  login: () => path.join(urls.root, 'login'),
  logout: () => path.join(urls.root, 'logout'),
  profile: () => path.join(urls.root, 'me'),
  forgotPassword: () => path.join(urls.root, 'forgot-password'),
  resetPassword: () => path.join(urls.root, 'reset-password'),
  validateMagicToken: () => path.join(urls.root, 'magic-login'),
  resetTokenStatus: () => path.join(urls.resetPassword(), 'token-status')
};

function login(credentials) {
  return request.base.post(urls.login(), credentials)
    .then(extractData)
    .then(({ user }) => user);
}

function logout() {
  return request.get(urls.logout());
}

function forgotPassword(email) {
  return request.post(urls.forgotPassword(), { email });
}

function resetPassword(token, password) {
  return request.base.post(urls.resetPassword(), { token, password });
}

function validateResetToken(token) {
  return request.base.post(urls.resetTokenStatus(), { token });
}

function getUserInfo() {
  return request.base.get(urls.profile())
    .then(extractData);
}

function validateMagicToken({ token }) {
  return request.base.post(urls.validateMagicToken(), { token })
    .then(extractData)
    .then(({ user }) => user);
}

export default {
  login,
  logout,
  forgotPassword,
  resetPassword,
  validateResetToken,
  validateMagicToken,
  getUserInfo
};
