import { extractData, processParams } from '@/common/api/helpers';
import join from 'url-join';
import request from '@/common/api/request';

const urls = {
  root: '/packages',
  resource: id => join(urls.root, `${id}`),
  copy: id => join(urls.resource(id), 'copy'),
  email: id => join(urls.resource(id), 'email')
};

function fetch(opts = {}) {
  const params = processParams(opts);
  return request.get(urls.root, { params }).then(extractData);
}

function get(id) {
  return request.get(urls.resource(id)).then(extractData);
}

function create(params) {
  return request.post(urls.root, params).then(extractData);
}

function update({ id, ...params }) {
  return request.patch(urls.resource(id), params).then(extractData);
}

function copy({ copyId, ...params }) {
  return request.post(urls.copy(copyId), params).then(extractData);
}

function remove(item = {}) {
  return request.delete(urls.resource(item.id)).then(extractData);
}

function sendEmail({ id, ...params }) {
  return request.post(urls.email(id), params);
}

export default {
  fetch,
  get,
  copy,
  create,
  update,
  remove,
  sendEmail
};
